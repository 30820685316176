.sellcontract-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.contract-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.contract-button:disabled {
  background-color: #535659 !important;
}

.sellcontract-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .sellcontract-container {
    margin-bottom: 25px;
  }

  .sellcontract-drawer {
    width: 265.34px !important;
  }
}

@media screen and (max-width:600px) {
  .sellcontract-paper-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.text-capital {
  text-transform: capitalize;
}

.contract-package-container {
  padding: 10px;
  border-radius: 12px !important;
}

.contract-package-container:nth-child(n+2) {
  margin-top: 10px;
}

.package-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.package_details {
  flex: 5;
}

.package_iconbtn_container{
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}

.repair-sell-contract-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
}

.add-icon-container {
  flex: 5;
}

.contract-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
}

.contract-image-label {
  flex: 5;
}

.contract-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin-top: 10px;
}

.sell-contract-upload-Container{
  border: 2px dashed #002E6E;
  margin-top: 5px;
  border-radius: 12px;
}

.sell-contract-upload-Icon{
  width: 80px !important;
  height: 80px !important;
  color:#002E6E ;
}

.sell-contract-label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.contract-title {
  font-size: clamp(1rem, 1.5vw + .5rem, 1.12rem) !important;
  font-weight: 600 !important;
}

.contract-subtitle {
  font-size: clamp(0.94rem, 1.5vw + .5rem, 1rem) !important;
}

.contract-description {
  font-size: clamp(0.9rem, 1.5vw + .5rem, 0.96rem) !important;
}


.contract-pdf-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 14px 0px;
  background-color: #002E6E;
  border-radius: 12px;
  padding: 6px;
}

.contract-pdf-icon{
  width: 26px !important;
  height: 26px !important;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.contract-pdf-filename{
  flex: 1;
  padding: 5px;
  color: white;
}

.pdf-container{
  display: flex;
  margin-top: 10px;
}

