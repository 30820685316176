.inventory-container {
    max-width: 700px;
    width: 100%;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .inventory-Drawer {
        width: 265.34px !important;
    }

    .inventory-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

.alert_btn {
    text-transform: none !important;
    font-size: 16px !important;
}

.tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.content-container {
    flex: 1;
    overflow-y: auto;
    /* padding: 20px 0px; */
}

@media screen and (max-width:600px) {
    .content-container {
        padding: 20px 0px;
    }
}