* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.main-Div {
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 4px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (min-width:600px) {
    .main-Div {
        border: 2px solid #00B9F1;
    }
}

.header-Div {
    background-color: #00B9F1;
    color: #fff;
    padding: 10px;
}

.Avatar-style {
    background-color: #fff !important;
    width: 60px !important;
    height: 60px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
}

.icon-style {
    width: 32px !important;
    height: 32px !important;
    color: #002E6E;
}

.grid-container {
    padding: 10px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
}

.card-box {
    padding-top: 4px;
}

.sub_title {
    font-size: clamp(0.8rem, 1.5vw + .5rem, 1rem) !important;
}

.main_title {
    font-size: clamp(1.25rem, 2.5vw + .5rem, 1.5rem) !important;
}

@media screen and (max-width:600px) {

    .grid-container {
        padding: 8px;
        gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .card-box {
        padding: 0px;
    }

    .Avatar-style {
        width: 46px !important;
        height: 46px !important;
    }

}