* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.edit-job-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
}

.edit-job-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.edit-job-button:disabled {
  background-color: #535659 !important;
}

.edit-job-skills-container {
  display: flex;
  flex-wrap: wrap;
}