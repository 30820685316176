* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.show-interested-container{
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .show-interested-drawer {
        width: 265.34px !important;
    }

    .show-interested-container{
        margin-bottom: 25px !important;
    }
}

.interested-user-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.interested-user-list {
    margin: 14px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.interested-user-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.interested-user-Name {
    text-transform: capitalize;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.interested-user_title {
    font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
    font-weight: 600 !important;
    display: inline-block;
    margin-right: 8px !important;
}

.interested-user_subtitle {
    font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

.interested-user_subtitle1 {
    font-size: clamp(0.90rem, 1.5vw + .5rem, 1.10rem) !important;
}

.chat-lightmode-icon {
    color: #002E6E !important;
}

.chat-darkmode-icon {
    color: #00B9F1 !important;
}

.call-button {
    padding: 0px 10px !important;
}
