* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.showcase-product-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.showcase-product-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .showcase-product-drawer {
    width: 265.34px !important;
  }

  .showcase-product-container {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width:600px) {
  .showcase-product-paper-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.showcase-product-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.showcase-product-button:disabled {
  background-color: #535659 !important;
}

.showcase-product-editor-container {
  height: 300px;
  margin-top: 10px !important;
  margin-bottom: 60px !important;
}

.showcase-product-texteditor {
  height: 100%;
  border: none;
}

@media screen and (max-width:492px) {
  .showcase-product-editor-container {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width:351px) {
  .showcase-product-editor-container {
    margin-bottom: 105px !important;
  }
}

.showcase-product-upload-Container {
  border: 2px dashed #002E6E;
  margin-top: 5px;
  border-radius: 12px;
}

.showcase-product-upload-Icon {
  width: 80px !important;
  height: 80px !important;
  color: #002E6E;
}

.showcase-product-upload-fileInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 14px 0px;
  background-color: #002E6E;
  border-radius: 12px;
  padding: 6px;
}

.showcase-product-upload-fileIcon {
  width: 26px !important;
  height: 26px !important;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.showcase-product-file-name {
  flex: 1;
  padding: 5px;
  color: white;
}

.new-product-launch-label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

