.sellproduct-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.sellproduct-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.sellproduct-button:disabled {
  background-color: #535659 !important;
}

.sellproduct-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .sellproduct-container {
    margin-bottom: 25px;
  }

  .sellproduct-drawer {
    width: 265.34px !important;
  }
}

.text-capital {
  text-transform: capitalize;
}

@media screen and (max-width:600px) {
  .sellproduct-paper-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.sell-product-upload-container{
  border: 2px dashed #002E6E;
  margin-top: 5px;
  border-radius: 12px;
}

.sell-product-upload-Icon{
  width: 80px !important;
  height: 80px !important;
  color:#002E6E ;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin-top: 10px;
}

.sell-product-label {
  font-size: 18px !important;
  font-weight: 500 !important;
}