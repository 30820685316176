.inventoryproductcard-btn {
  text-transform: none !important;
  border-radius: 15px !important;
  padding: 8px 0px !important;
  margin: 6px 0px !important;
  color: #fff !important;
}

.text-capital {
  text-transform: capitalize;
}

.inventoryproduct-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.inventory-product-card {
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width:600px) {
  .inventory-product-card {
    margin: 0px 6px;
  }
}

/* new card designs */

.product-card {
  margin: 20px 6px;
  display: flex;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  overflow: hidden;
}

.product-img-container {
  flex: 1;
}

.product-img {
  display: block;
  width: 100%;
  object-fit: fill;
  height: 100%;
}

.product-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  padding: 10px;
}

.product-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.product-btn-container {
  /* flex: 1; */
  align-self: flex-end;
  margin-top: auto;
}

.product-button {
  border-radius: 12px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  color: #fff !important;
}

.productdelete-btn {
  background-color: #db0505 !important;
}
